import $axios from '@/utils/request'

// 审核补课视频列表
export function getListUserExamByTeacher (data) {
  const url = '/exam/listUserExamByTeacher'
  return $axios.fPost(url, data)
}

// 获取试卷作答统计列表-根据排课
export function getListExamUserStatByLesson (data) {
  const url = '/exam/listExamUserStatByLesson'
  return $axios.fPost(url, data)
}

// 获取试卷作答列表-根据排课
export function getListExamUserByLesson (data) {
  const url = '/exam/listExamUserByLesson'
  return $axios.fPost(url, data)
}
